<template >
  <div>
    <div class="row">
      <q-card flat bordered class="main-card q-mt-md q-pa-lg q-ml-xl gt-sm">
        <div class="card-content q-pa-xl text-secondary">
          <q-card-section>
            <div class="text-h3 text-primary">Begeleiding</div>
          </q-card-section>
          <q-card-section class="q-pt-none text-h6">
            AbAut biedt begeleiding bij autisme, t.a.v. wonen, werken, school,
            vrije tijd en relaties.
          </q-card-section>
          <q-separator />

          <q-card-section class="text-h6 q-py-lg">
            Vanuit wie je bent, kijken we waar jij en je omgeving tegenaan lopen
            en hoe je het beste tot je recht zou komen. Eerst kijken we naar je
            omgeving, omdat niemand kan groeien als hij alleen maar aan het
            overleven is. Daarna kijken we samen naar wat je wil leren of
            veranderen en op welke manier je het meeste kans op succes hebt.
          </q-card-section>
          <q-separator />

          <q-card-section class="text-h6 q-py-lg">
            Mijn uitgangspunt is vertrouwen. Ik geloof dat je iets (hier, nu,
            zo) echt niet kan. Ook al ben je enorm intelligent. En ik schrijf
            geen enkele droom bij voorbaat af.
          </q-card-section>
          <q-separator />

          <q-card-section class="text-h6 q-py-lg">
            Als er één ding wat veel onbegrip geeft, dan is het wel hoe groot
            het verschil kan zijn tussen de 'topprestatie' die je op het ene
            moment kan leveren en het schijnbare 'kleinigheidje' wat je in een
            andere situatie helemaal laat blokkeren. In een passende omgeving is
            veel meer mogelijk dan je verwacht.
          </q-card-section>
        </div>
      </q-card>

      <p
        class="text-white absolute-right q-pa-xl text-h5 q-ma-xl gt-sm"
        style="width: 30%; height: min-content"
      >
        Begeleiding kan helpen als wat je kan, wat je wil, wat je doet en wat je
        moet niet op één lijn liggen.
      </p>
    </div>
    <q-card flat bordered class="q-mt-md lt-md mobile-card">
      <div class="text-secondary mobile-card-content">
        <q-card-section>
          <div class="text-h5 text-primary text-weight-medium">Begeleiding</div>
        </q-card-section>

        <q-card-section
          class="q-pt-none text-body1 text-accent text-weight-medium"
        >
          Begeleiding kan helpen als wat je kan, wat je wil, wat je doet en wat
          je moet niet op één lijn liggen.
        </q-card-section>

        <q-card-section class="q-pt-none text-body1 text-weight-medium">
          AbAut biedt begeleiding bij autisme, t.a.v. wonen, werken, school,
          vrije tijd en relaties.
        </q-card-section>

        <q-card-section class="text-body1 text-weight-medium">
          Vanuit wie je bent, kijken we waar jij en je omgeving tegenaan lopen
          en hoe je het beste tot je recht zou komen. Eerst kijken we naar je
          omgeving, omdat niemand kan groeien als hij alleen maar aan het
          overleven is. Daarna kijken we samen naar wat je wil leren of
          veranderen en op welke manier je het meeste kans op succes hebt.
        </q-card-section>

        <q-card-section class="text-body1 text-weight-medium">
          Mijn uitgangspunt is vertrouwen. Ik geloof dat je iets (hier, nu, zo)
          echt niet kan. Ook al ben je enorm intelligent. En ik schrijf geen
          enkele droom bij voorbaat af.
        </q-card-section>
        <q-card-section class="text-body1 text-weight-medium">
          Als er één ding wat veel onbegrip geeft, dan is het wel hoe groot het
          verschil kan zijn tussen de 'topprestatie' die je op het ene moment
          kan leveren en het schijnbare 'kleinigheidje' wat je in een andere
          situatie helemaal laat blokkeren. In een passende omgeving is veel
          meer mogelijk dan je verwacht.
        </q-card-section>
      </div>
    </q-card>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";

export default {};
</script>

<style scoped>
.main-card {
  width: 60%;
  min-height: 85vh;
  transform: rotate(-2deg);
  border-radius: 55px;
  z-index: 1999;
  border: var(--q-primary) solid 3px;
}
.card-content {
  transform: rotate(2deg);
}

.mobile-card {
  transform: rotate(-1deg);
  border-radius: 45px;
  z-index: 1999;
  border: var(--q-primary) solid 3px;
}

.mobile-card-content {
  transform: rotate(1deg);
}
</style>
