import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/begeleiding',
    name: 'Begeleiding',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Begeleiding.vue')
    }
  },
  {
    path: '/vergoeding',
    name: 'Vergoeding',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Vergoeding.vue')
    }
  },
  {
    path: '/about',
    name: 'About',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/About.vue')
    }
  },
  {
    path: '/links',
    name: 'Links',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Links.vue')
    }
  },{
    path: '/contact',
    name: 'Contact',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Contact.vue')
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
