<template >
  <div>
    <div class="row">
      <q-card flat bordered class="main-card q-mt-md q-pa-lg q-ml-xl gt-sm">
        <div class="card-content q-pa-xl text-secondary">
          <q-card-section>
            <div class="text-h3 text-primary">Over mij</div>
          </q-card-section>
          <q-card-section class="q-pt-none text-h6">
            Autisme is in mijn leven overal aanwezig. 
In mijn familie, mijn vriendenkring en in mijn werk. 
Naast mijn werk met eigen cliënten, werk ik parttime in loondienst als bewindvoerder en mentor voor mensen met (o.a.) autisme.
          </q-card-section>
          <q-separator />

          <q-card-section class="text-h6 q-py-lg">
            Na mijn hbo-IW opleiding heb ik o.a. gewerkt in een gezinsvervangend tehuis voor mensen met een cognitieve beperking. 
Daar kwam ik in 2003 in aanraking met een bewoner die echt een uitdaging voor het team vormde. We zagen wel dat onder zijn gedrag oprecht alleen maar onmacht zat. Door met familie, oud-begeleiders en vrijwilligers te praten, kwamen we samen met hem tot een beschrijving wat wel werkte en wat niet. Ik wist toen nog bijna niets van autisme.
Toen ik onze beschrijving aan de gedragsdeskundige gaf, besloot ze hem te testen, omdat dit 1 op 1 de “auti-benadering” was. Hij kreeg de diagnose en ik realiseerde me dat, als dit autisme was, wat ik had geleerd niets meer waard was.
          </q-card-section>
          <q-separator />

          <q-card-section class="text-h6 q-py-lg">
            Ik begon aan de jaaropleiding autisme bij Fontys en werd zo geraakt dat ik besloot te gaan werken met mensen met autisme en een normale intelligentie. In 2010 heb ik de master SEN Autisme afgerond.
          </q-card-section>

        </div>
      </q-card>

      <p
        class="text-white absolute-right q-pa-xl text-h5 q-ma-xl gt-sm"
        style="width: 30%; height: min-content"
      >
        Begeleiding kan helpen als wat je kan, wat je wil, wat je doet en wat je
        moet niet op één lijn liggen.
      </p>
    </div>
    <q-card flat bordered class="q-mt-md lt-md mobile-card">
      <div class="text-secondary mobile-card-content">
        <q-card-section>
          <div class="text-h5 text-primary text-weight-medium">Over mij</div>
        </q-card-section>

        <q-card-section
          class="q-pt-none text-body1 text-accent text-weight-medium"
        >
          Begeleiding kan helpen als wat je kan, wat je wil, wat je doet en wat
          je moet niet op één lijn liggen.
        </q-card-section>

        <q-card-section class="q-pt-none text-body1 text-weight-medium">
          Autisme is in mijn leven overal aanwezig. 
In mijn familie, mijn vriendenkring en in mijn werk. 
Naast mijn werk met eigen cliënten, werk ik parttime in loondienst als bewindvoerder en mentor voor mensen met (o.a.) autisme.
        </q-card-section>

        <q-card-section class="text-body1 text-weight-medium">
          Na mijn hbo-IW opleiding heb ik o.a. gewerkt in een gezinsvervangend tehuis voor mensen met een cognitieve beperking. 
Daar kwam ik in 2003 in aanraking met een bewoner die echt een uitdaging voor het team vormde. We zagen wel dat onder zijn gedrag oprecht alleen maar onmacht zat. Door met familie, oud-begeleiders en vrijwilligers te praten, kwamen we samen met hem tot een beschrijving wat wel werkte en wat niet. Ik wist toen nog bijna niets van autisme.
Toen ik onze beschrijving aan de gedragsdeskundige gaf, besloot ze hem te testen, omdat dit 1 op 1 de “auti-benadering” was. Hij kreeg de diagnose en ik realiseerde me dat, als dit autisme was, wat ik had geleerd niets meer waard was.
        </q-card-section>

        <q-card-section class="text-body1 text-weight-medium">
          Ik begon aan de jaaropleiding autisme bij Fontys en werd zo geraakt dat ik besloot te gaan werken met mensen met autisme en een normale intelligentie. In 2010 heb ik de master SEN Autisme afgerond.
        </q-card-section>
      
      </div>
    </q-card>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";

export default {};
</script>

<style scoped>
.main-card {
  width: 60%;
  min-height: 85vh;
  transform: rotate(-2deg);
  border-radius: 55px;
  z-index: 1999;
  border: var(--q-primary) solid 3px;
}
.card-content {
  transform: rotate(2deg);
}

.mobile-card {
  transform: rotate(-1deg);
  border-radius: 45px;
  z-index: 1999;
  border: var(--q-primary) solid 3px;
}

.mobile-card-content {
  transform: rotate(1deg);
}
</style>
