<template >
  <div>
    <div class="row">
      <q-card flat bordered class="main-card q-mt-md q-pa-lg q-ml-xl gt-sm">
        <div class="card-content q-pa-xl text-secondary">
          <q-card-section>
            <div class="text-h3 text-primary">Contact</div>
          </q-card-section>
          <q-card-section class="q-pt-none text-h6">
            <q-btn
            flat
            target="_blank"
            href="https://www.linkedin.com/in/biancaebeling/"
            dense
            class="bg-white q-mr-md"
          >
            <img
              style="height: 55px"
              src="https://www.sfdcamplified.com/wp-content/uploads/2019/04/linkedin-logo-copy.png"
            />
          </q-btn>
          <q-btn
            flat
            target="_blank"
            href="https://www.ibva.info/kwaliteitsregister/contact-met-specialist/51/"
            dense
            class="bg-white q-mr-md"
          >
            <img
              style="height: 55px"
              src="https://www.zo-zien.nl/wp-content/uploads/2019/08/logo-ibva-voor-eigen-gebruik-leden.png"
            />
          </q-btn>
          <q-btn
            flat
            target="_blank"
            href="https://www.facebook.com/AbAut.autismebegeleiding"
            dense
            class="bg-white q-mr-md"
          >
            <img
              style="height: 55px"
              src="https://www.bclimes.nl/wp-content/uploads/2017/06/facebook-transparent-logo.png"
            />
          </q-btn>
          <q-btn
            flat
            target="_blank"
            href="mailto:bianca@abaut.nl"
            dense
            class="bg-white"
          >
            <img
              style="height: 55px"
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/90/Outlook.com_icon_%282012-2019%29.svg/1200px-Outlook.com_icon_%282012-2019%29.svg.png"
            />
          </q-btn>
          </q-card-section>

        </div>
      </q-card>

      <p
        class="text-white absolute-right q-pa-xl text-h5 q-ma-xl gt-sm"
        style="width: 30%; height: min-content"
      >
        Begeleiding kan helpen als wat je kan, wat je wil, wat je doet en wat je
        moet niet op één lijn liggen.
      </p>
    </div>
    <q-card flat bordered class="q-mt-md lt-md mobile-card">
      <div class="text-secondary mobile-card-content">
        <q-card-section>
          <div class="text-h5 text-primary text-weight-medium">Contact</div>
        </q-card-section>

        <q-card-section
          class="q-pt-none text-body1 text-accent text-weight-medium"
        >   <q-btn
            flat
            target="_blank"
            href="https://www.linkedin.com/in/biancaebeling/"
            dense
            class="bg-white q-mr-md"
          >
            <img
              style="height: 55px"
              src="https://www.sfdcamplified.com/wp-content/uploads/2019/04/linkedin-logo-copy.png"
            />
          </q-btn>
          <q-btn
            flat
            target="_blank"
            href="https://www.ibva.info/kwaliteitsregister/contact-met-specialist/51/"
            dense
            class="bg-white q-mr-md"
          >
            <img
              style="height: 55px"
              src="https://www.zo-zien.nl/wp-content/uploads/2019/08/logo-ibva-voor-eigen-gebruik-leden.png"
            />
          </q-btn>
          <q-btn
            flat
            target="_blank"
            href="https://www.facebook.com/AbAut.autismebegeleiding"
            dense
            class="bg-white q-mr-md"
          >
            <img
              style="height: 55px"
              src="https://www.bclimes.nl/wp-content/uploads/2017/06/facebook-transparent-logo.png"
            />
          </q-btn>
          <q-btn
            flat
            target="_blank"
            href="mailto:bianca@abaut.nl"
            dense
            class="bg-white"
          >
            <img
              style="height: 55px"
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/90/Outlook.com_icon_%282012-2019%29.svg/1200px-Outlook.com_icon_%282012-2019%29.svg.png"
            />
          </q-btn> </q-card-section>
      
      </div>
    </q-card>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";

export default {};
</script>

<style scoped>
.main-card {
  width: 60%;
  min-height: 85vh;
  transform: rotate(-2deg);
  border-radius: 55px;
  z-index: 1999;
  border: var(--q-primary) solid 3px;
}
.card-content {
  transform: rotate(2deg);
}

.mobile-card {
  transform: rotate(-1deg);
  border-radius: 45px;
  z-index: 1999;
  border: var(--q-primary) solid 3px;
}

.mobile-card-content {
  transform: rotate(1deg);
}
</style>
