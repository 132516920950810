<template>
  <q-layout view="hHH lpr ffr" container style="height: 100vh" class="shadow-2">
    <q-header >
      <q-toolbar class="bg-white q-pl-none">
        <img style="height: 60px; margin: 7px; border-radius: 7px;" src="./assets/abaut-logo.png" />
        <q-toolbar-title></q-toolbar-title>
        <q-tabs class="text-secondary gt-sm">
          <q-route-tab to="/"  label="Over mij" />
          <q-route-tab to="/begeleiding"  label="Begeleiding" />
          <!-- <q-route-tab to="/vergoeding"  label="Vergoeding" /> -->
          <!-- <q-route-tab to="/about"  label="Over mij" /> -->
          <q-route-tab to="/links"  label="Links" />
          <q-route-tab to="/contact"  label="Contact" />
        </q-tabs>
        <div class="float-right text-black">
          <q-btn
            class="lt-md"
            flat
            @click="drawer = !drawer"
            round
            dense
            icon="menu"
          />
        </div>
      </q-toolbar>
    </q-header>
    <q-drawer
      v-model="drawer"
      :width="300"
      :breakpoint="500"
      overlay
      bordered
      side="right"
      class="bg-white"
    >
      <q-scroll-area class="fit">
        <q-list>
          <template v-for="(menuItem, index) in menuList" :key="index">
            <q-item :to="menuItem.href" clickable v-ripple>
              <q-item-section>
                {{ menuItem.label }}
              </q-item-section>
            </q-item>
            <q-separator :key="'sep' + index" v-if="menuItem.separator" />
          </template>
        </q-list>
      </q-scroll-area>
    </q-drawer>
     <div style="height: 50px; width: 100%; position: absolute; bottom: 0px; z-index: 1998" class="bg-primary">
    </div>

    <q-page-container>
      <q-page class="q-pa-md bg-accent">
        <router-view class=" q-mx-lg"></router-view>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script>
import { ref } from "vue";

const menuList = [
  {
    label: "Over mij",
    href: "/"
  },
  {
    label: "Begeleiding",
    href: "/begeleiding"
  },
  {
    label: "Links",
    href: "/links"
  },
  {
    label: "Contact",
    href: "/contact"
  }
];

export default {
  setup() {
    return {
      drawer: ref(false),
      menuList,
    };
  },
};
</script>