<template >
  <div>
    <q-card flat bordered class="main-card q-mt-md q-pa-lg q-ml-xl">
      <div class="card-content q-pa-xl text-secondary">
        <q-card-section>
          <div class="text-h3 text-primary">Vergoeding</div>
        </q-card-section>

        <q-card-section class="q-pt-none text-h5">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </q-card-section>

        <q-separator inset />

        <q-card-section class="text-h5">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </q-card-section>
      </div>
    </q-card>
    <p class=" text-white absolute-right q-pa-xl text-h5 q-mt-md" style="width: 30%">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
    </p>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";

export default {};
</script>

<style scoped>
.main-card {
  width: 60%;
  transform: rotate(-2deg);
  border-radius: 55px;
  position: absolute;
  height: 98%;
  z-index: 9999;
  border: var(--q-primary) solid 3px;
}
.card-content {
  transform: rotate(2deg);
}
</style>
