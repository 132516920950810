
import 'quasar/dist/quasar.css'
import lang from 'quasar/lang/en-US'
import '@quasar/extras/roboto-font/roboto-font.css'
import '@quasar/extras/bootstrap-icons'
import '@quasar/extras/material-icons/material-icons.css'

// To be used on app.use(Quasar, { ... })
export default {
  config: {
    brand: {
      primary: '#957db9',
      secondary: '#050d41',
      accent: '#bfb1d5',
      dark: '#030721',
      positive: '#009c99',
      negative: '#940011',
      info: '#00729c',
      warning: '#9c7a00'
    }
  },
  plugins: {
  },
  lang: lang
}