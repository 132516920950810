<template >
  <div>
    <div class="row">
      <q-card flat bordered class="main-card q-mt-md q-pa-lg q-ml-xl gt-sm">
        <div class="card-content q-pa-xl text-secondary">
          <q-card-section>
            <div class="text-h3 text-primary">Links</div>
          </q-card-section>
          <q-card-section class="q-pt-none text-h6">
           <a style="color: #000053" href="https://www.ibva.info/informatie/2/over-ibva.html">IBVA - Informatie</a><br/>
           <a style="color: #000053" href="https://www.zzp-erindezorg.nl/klachtenregeling ">ZZP-er in de zorg - Klachtenregeling</a><br/>
           <a style="color: #000053" href="http://www.abaut.nl/files/diagnose_autisme.pdf">Uitleg over de diagnose</a><br/>
           <a style="color: #000053" href="https://hbo-kennisbank.nl/details/sharekit_fontys:oai:surfsharekit.nl:89536f8a-3fb1-42a4-95b8-51f3cf71a4a4?has-link=yes&q=moet+ik+het+even+voor+je+uittekenen&c=0">"Moet ik het even voor je uittekenen?" (master thesis Master SEN Autismespecialist) </a><br/>
           <a style="color: #000053" href="https://m.facebook.com/groups/669421607608625/permalink/799282544622530/">Tips bij autisme - Vluchtelingenopvang</a><br/>
          </q-card-section>
        </div>
      </q-card>

      <p
        class="text-white absolute-right q-pa-xl text-h5 q-ma-xl gt-sm"
        style="width: 30%; height: min-content"
      >
        Begeleiding kan helpen als wat je kan, wat je wil, wat je doet en wat je
        moet niet op één lijn liggen.
      </p>
    </div>
    <q-card flat bordered class="q-mt-md lt-md mobile-card">
      <div class="text-secondary mobile-card-content">
        <q-card-section>
          <div class="text-h5 text-primary text-weight-medium">Links</div>
        </q-card-section>

        <q-card-section
          class="q-pt-none text-body1 text-accent text-weight-medium"
        >
        <a style="color: #000053" href="https://www.ibva.info/informatie/2/over-ibva.html">IBVA - Informatie</a><br/>
           <a style="color: #000053" href="https://www.zzp-erindezorg.nl/klachtenregeling ">ZZP-er in de zorg - Klachtenregeling</a><br/>
           <a style="color: #000053" href="http://www.abaut.nl/files/diagnose_autisme.pdf">Uitleg over de diagnose</a><br/>
           <a style="color: #000053" href="https://hbo-kennisbank.nl/details/sharekit_fontys:oai:surfsharekit.nl:89536f8a-3fb1-42a4-95b8-51f3cf71a4a4?has-link=yes&q=moet+ik+het+even+voor+je+uittekenen&c=0">"Moet ik het even voor je uittekenen?" (master thesis Master SEN Autismespecialist) </a><br/>
          </q-card-section>
      
      </div>
    </q-card>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";

export default {};
</script>

<style scoped>
.main-card {
  width: 60%;
  min-height: 85vh;
  transform: rotate(-2deg);
  border-radius: 55px;
  z-index: 1999;
  border: var(--q-primary) solid 3px;
}
.card-content {
  transform: rotate(2deg);
}

.mobile-card {
  transform: rotate(-1deg);
  border-radius: 45px;
  z-index: 1999;
  border: var(--q-primary) solid 3px;
}

.mobile-card-content {
  transform: rotate(1deg);
}
</style>
